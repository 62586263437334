//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import generateID from '@/utils/generateID'
import { mapState } from 'vuex'
import Preview from '@/components/Editor/Preview'
import { commonStyle, commonAttr } from '@/custom-component/component-list'
import eventBus from '@/utils/eventBus'
import { deepCopy } from '@/utils/utils'
import { request } from '@/utils/request'
import { SavePageInfo } from '@/utils/api'
import selectImage from '@/components/Editor/selectImage'

export default {
    components: { Preview, selectImage },
    data() {
        return {
            isShowPreview: false,
            isShowSelectImage: false,
            showSelectImage: false,
            needToChange: [
                'top',
                'left',
                'width',
                'height',
                'fontSize',
                'borderWidth',
            ],
            scale: '100%',
            timer: null,
        }
    },
    computed: mapState([
        'componentData',
        'canvasStyleData',
        'areaData',
        'curComponent',
        'pageIds',
        'pageTitle',
    ]),
    created() {
        eventBus.$on('preview', this.preview)
        eventBus.$on('save', this.save)
        eventBus.$on('clearCanvas', this.clearCanvas)

        this.scale = this.canvasStyleData.scale
    },
    methods: {
        parentSelectImage(val) {
            this.$store.commit('addComponent', {
                component: {
                    ...commonAttr,
                    id: generateID(),
                    component: 'Picture',
                    label: '图片',
                    icon: '',
                    propValue: val.url,
                    style: {
                        ...commonStyle,
                        top: 0,
                        left: 0,
                        width: '100',
                        height: '100',
                    },
                },
            })
            this.$store.commit('recordSnapshot')
            this.isShowSelectImage = false
            this.showSelectImage = false
        },
        selectImage() {
            this.isShowSelectImage = true
            this.showSelectImage = true
        },
        format(value) {
            const scale = this.scale
            return value * parseInt(scale) / 100
        },

        getOriginStyle(value) {
            const scale = this.canvasStyleData.scale
            const result = value / (parseInt(scale) / 100)
            return result
        },

        handleScaleChange() {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                // 画布比例设一个最小值，不能为 0
                // eslint-disable-next-line no-bitwise
                this.scale = (~~this.scale) || 1
                const componentData = deepCopy(this.componentData)
                componentData.forEach(component => {
                    Object.keys(component.style).forEach(key => {
                        if (this.needToChange.includes(key)) {
                            // 根据原来的比例获取样式原来的尺寸
                            // 再用原来的尺寸 * 现在的比例得出新的尺寸
                            // 不能用 Math.round 防止 1 px 的边框变 0
                            component.style[key] = Math.ceil(this.format(this.getOriginStyle(component.style[key])))
                        }
                    })
                })

                this.$store.commit('setComponentData', componentData)
                this.$store.commit('setCanvasStyle', {
                    ...this.canvasStyleData,
                    scale: this.scale,
                })
            }, 1000)
        },

        lock() {
            this.$store.commit('lock')
        },

        unlock() {
            this.$store.commit('unlock')
        },

        compose() {
            this.$store.commit('compose')
            this.$store.commit('recordSnapshot')
        },

        decompose() {
            this.$store.commit('decompose')
            this.$store.commit('recordSnapshot')
        },

        undo() {
            this.$store.commit('undo')
        },

        redo() {
            this.$store.commit('redo')
        },

        // handleFileChange(e) {
        //     let vm = this
        //     const file = e.target.files[0]
        //     if (!file.type.includes('image')) {
        //         toast('只能插入图片')
        //         return
        //     }
        //     const formData = new FormData()
        //     formData.append('file', file)
        //     formData.append('file_type', 'image')
        //     formData.append('data_name', 'AntBlessPage')
        //     formData.append('file_name', file.name)
        //     formData.append('file_size', file.size)
        //     formData.append('mime_type', file.type)
        //     const reader = new FileReader()
        //     reader.onload = (res) => {
        //         // const fileResult = res.target.result
        //         const img = new Image()
        //         request(UploadByFile, 'post', formData).then(re => {
        //             if (re.data.code === 1) {
        //                 img.onload = () => {
        //                     this.$store.commit('addComponent', {
        //                         component: {
        //                             ...commonAttr,
        //                             id: generateID(),
        //                             component: 'Picture',
        //                             label: '图片',
        //                             icon: '',
        //                             propValue: re.data.data.fileUrl,
        //                             style: {
        //                                 ...commonStyle,
        //                                 top: 0,
        //                                 left: 0,
        //                                 width: img.width,
        //                                 height: img.height,
        //                             },
        //                         },
        //                     })
        //                     this.$store.commit('recordSnapshot')
        //                     // 修复重复上传同一文件，@change 不触发的问题
        //                     document.querySelector('#input').setAttribute('type', 'text')
        //                     document.querySelector('#input').setAttribute('type', 'file')
        //                 }
        //                 img.src = re.data.data.fileUrl
        //             } else {
        //                 vm.$message.error(re.data.message)
        //             }
        //         })
        //     }
        //     reader.readAsDataURL(file)
        // },

        preview() {
            this.isShowPreview = true
            this.$store.commit('setEditMode', 'preview')
        },

        save() {
            let vm = this
            let params = {
                pageId: vm.pageIds,
                pageData: vm.componentData,
                pageStyle: vm.canvasStyleData,
            }
            request(SavePageInfo, 'post', params).then(res => {
                if (res.data.code === 1) {
                    localStorage.setItem('canvasData', JSON.stringify(this.componentData))
                    localStorage.setItem('canvasStyle', JSON.stringify(this.canvasStyleData))
                    vm.$message.success('保存成功')
                } else {
                    vm.$message.error(res.data.message)
                }
            })
        },

        clearCanvas() {
            this.$store.commit('setCurComponent', { component: null, index: null })
            this.$store.commit('setComponentData', [])
            this.$store.commit('recordSnapshot')
        },

        handlePreviewChange() {
            this.$store.commit('setEditMode', 'edit')
        },
        handleSelectImageChange() {

        },
    },
}
