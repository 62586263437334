import axios from 'axios'

axios.defaults.timeout = 5000
// axios.defaults.withCredentials = false

axios.defaults.headers.common['Access-Token'] = 'HqovTbMscFFeEUQiNsEBZYcxCGpHMRlq'
axios.defaults.headers.common['Think-Lang'] = 'zh-cn'
const apiAuth = localStorage.getItem('api-auth')

axios.defaults.headers.common['Api-Auth'] = apiAuth
const METHOD = {
    GET: 'get',
    POST: 'post',
}
async function request(url, method, params) {
    switch (method) {
        case METHOD.GET:
            return axios.get(url, { params })
        case METHOD.POST:
            return axios.post(url, params)
        default:
            return axios.get(url, params)
    }
}
export {
    request,
}
