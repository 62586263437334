//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Editor from '@/components/Editor/index'
import ComponentList from '@/components/ComponentList' // 左侧列表组件
import AttrList from '@/components/AttrList' // 右侧属性列表
import AnimationList from '@/components/AnimationList' // 右侧动画列表
import EventList from '@/components/EventList' // 右侧事件列表
import componentList from '@/custom-component/component-list' // 左侧列表数据
import Toolbar from '@/components/Toolbar'
import { deepCopy } from '@/utils/utils'
import { mapState } from 'vuex'
import generateID from '@/utils/generateID'
import { listenGlobalKeyDown } from '@/utils/shortcutKey'
import { request } from '@/utils/request'
import { GetPageInfo } from '@/utils/api'
import { Loading } from 'element-ui'

export default {
    components: { Editor, ComponentList, AttrList, AnimationList, EventList, Toolbar },
    data() {
        return {
            activeName: 'attr',
            reSelectAnimateIndex: undefined,
            pageId: 0,
        }
    },
    computed: mapState([
        'componentData',
        'curComponent',
        'isClickComponent',
        'canvasStyleData',
        'editor',
        'pageIds',
    ]),
    created() {
        this.pageId = this.$route.query.pid
        this.getPageData()
        // this.restore()
        // 全局监听按键事件
        listenGlobalKeyDown()
    },
    methods: {
        getPageData() {
            let vm = this
            if (vm.pageId > 0) {
                let loadingInstance = Loading.service({})
                request(GetPageInfo, 'post', { id: vm.pageId }).then(res => {
                    loadingInstance.close()
                    if (res.data.code === 1) {
                        // vm.$store.commit('setComponentData', vm.resetID(JSON.parse(res.data.data.page.page_data)))
                        // vm.$store.commit('setCanvasStyle', vm.resetID(JSON.parse(res.data.data.page.page_style)))
                        localStorage.setItem('canvasData', res.data.data.page.page_data)
                        localStorage.setItem('canvasStyle', res.data.data.page.page_style)
                        vm.$store.commit('setPageIdsData', vm.pageId)
                        vm.$store.commit('setPageTitleData', res.data.data.title)
                        vm.restore()
                    } else {
                        vm.$message.error('获取页面信息失败')
                    }
                })
            }
        },

        restore() {
            // 用保存的数据恢复画布
            if (localStorage.getItem('canvasData')) {
                this.$store.commit('setComponentData', this.resetID(JSON.parse(localStorage.getItem('canvasData'))))
            }

            if (localStorage.getItem('canvasStyle')) {
                this.$store.commit('setCanvasStyle', JSON.parse(localStorage.getItem('canvasStyle')))
            }
        },

        resetID(data) {
            if (data) {
                data.forEach(item => {
                    item.id = generateID()
                })
            }
            return data
        },

        handleDrop(e) {
            e.preventDefault()
            e.stopPropagation()
            const index = e.dataTransfer.getData('index')
            const rectInfo = this.editor.getBoundingClientRect()
            if (index) {
                const component = deepCopy(componentList[index])
                component.style.top = e.clientY - rectInfo.y
                component.style.left = e.clientX - rectInfo.x
                component.id = generateID()
                this.$store.commit('addComponent', { component })
                this.$store.commit('recordSnapshot')
            }
        },

        handleDragOver(e) {
            e.preventDefault()
            e.dataTransfer.dropEffect = 'copy'
        },

        handleMouseDown(e) {
            e.stopPropagation()
            this.$store.commit('setClickComponentStatus', false)
            this.$store.commit('setInEditorStatus', true)
        },

        deselectCurComponent(e) {
            if (!this.isClickComponent) {
                this.$store.commit('setCurComponent', { component: null, index: null })
            }

            // 0 左击 1 滚轮 2 右击
            if (e.button != 2) {
                this.$store.commit('hideContextMenu')
            }
        },
    },
}
