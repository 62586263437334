//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        start: {
            type: Object,
            default: () => {},
        },
        width: {
            type: Number,
            default: 0,
        },
        height: {
            type: Number,
            default: 0,
        },
    },
}
