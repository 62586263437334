//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { styleData } from '@/utils/style'
import selectImage from '@/components/Editor/selectImage'
import animationClassData from '@/utils/animationClassData'

export default {
    components: { selectImage },
    data() {
        return {
            isShowSelectImage: false,
            showSelectImage: false,
            selectImageType: '',
            excludes: ['Picture', 'Group', 'roll'], // 这些组件不显示内容
            excludesImage: ['roll'], // 滚动 内容为 图片
            textAlignOptions: [
                {
                    label: '左对齐',
                    value: 'left',
                },
                {
                    label: '居中',
                    value: 'center',
                },
                {
                    label: '右对齐',
                    value: 'right',
                },
            ],
            borderStyleOptions: [
                {
                    label: '实线',
                    value: 'solid',
                },
                {
                    label: '虚线',
                    value: 'dashed',
                },
            ],
            rollTypeOptions: [
                {
                    label: '横向',
                    value: 'lateral',
                },
                {
                    label: '竖向',
                    value: 'vertical',
                },
            ],
            catOptions: [
                {
                    label: '否',
                    value: '0',
                },
                {
                    label: '是',
                    value: '1',
                },
            ],
            writingModeOptions: [
                {
                    label: '垂直方向自右而左',
                    value: 'vertical-rl',
                },
                {
                    label: '垂直方向从上到下',
                    value: 'vertical-lr',
                },
                {
                    label: '从左向右，从上往下',
                    value: 'lr-tb',
                },
                {
                    label: '从上往下，从右向左',
                    value: 'tb-rl',
                },
                {
                    label: '水平方向自上而下',
                    value: 'horizontal-tb',
                },
            ],
            verticalAlignOptions: [
                {
                    label: '上对齐',
                    value: 'top',
                },
                {
                    label: '居中对齐',
                    value: 'middle',
                },
                {
                    label: '下对齐',
                    value: 'bottom',
                },
            ],
            selectKey: ['textAlign', 'borderStyle', 'verticalAlign', 'rollType', 'isCat', 'writingMode'],
            styleData,
            animationClassData,
            animationClassDataList: [],
        }
    },
    computed: {
        styleKeys() {
            if (this.$store.state.curComponent) {
                const curComponentStyleKeys = Object.keys(this.$store.state.curComponent.style)
                return this.styleData.filter(item => curComponentStyleKeys.includes(item.key))
            }
            return []
        },
        curComponent() {
            return this.$store.state.curComponent
        },
    },
    created() {
        let vm = this
        vm.animationClassData.forEach((item) => {
            item.children.forEach((v) => {
                vm.animationClassDataList.push({
                    label: v.label,
                    value: v.value,
                })
            })
        })
    },
    methods: {
        ShowSelectedImage(event) {
            this.selectImageType = event.target.dataset.type
            this.isShowSelectImage = true
            this.showSelectImage = true
        },
        parentSelectImage(val) {
            if (this.selectImageType === 'backgroundImage') {
                this.$store.state.curComponent.style.backgroundImage = val.url
            } else {
                this.$store.state.curComponent.propValue = val.url
            }
            this.isShowSelectImage = false
            this.showSelectImage = false
        },
    },
}
