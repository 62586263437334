//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from '@/components/Modal'
import eventBus from '@/utils/eventBus'
import animationClassData from '@/utils/animationClassData'
import { mapState } from 'vuex'
import runAnimation from '@/utils/runAnimation'
import AnimationSettingModal from './AnimationSettingModal.vue'

export default {
    components: { Modal, AnimationSettingModal },
    data() {
        return {
            isShowAnimation: false,
            hoverPreviewAnimate: '',
            animationActiveName: '进入',
            animationClassData,
            showAnimatePanel: false,
            timer: null,
            isShowAnimationSetting: false,
            curIndex: 0,
        }
    },
    computed: mapState([
        'curComponent',
    ]),
    methods: {
        addAnimation(animate) {
            this.$store.commit('addAnimation', animate)
            this.isShowAnimation = false
        },

        previewAnimate() {
            eventBus.$emit('runAnimation')
        },

        removeAnimation(index) {
            this.$store.commit('removeAnimation', index)
            if (!this.curComponent.animations.length) { // 清空动画数据，停止运动
                eventBus.$emit('stopAnimation')
            }
        },

        handleAnimationSetting(index) {
            this.isShowAnimationSetting = true
            this.curIndex = index
        },
        
        async runAnimation(animate) {
            if (animate.pending) return

            animate.pending = true
            await runAnimation(this.$refs[animate.value][0], [animate])

            // 防止无限触发同一元素的动画
            setTimeout(() => {
                animate.pending = false
            }, 100)  
        },
    },
}
