//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import eventBus from '@/utils/eventBus'

export default {
    name: 'AnimationSettingModal',
    props: {
        curIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            centerDialogVisible: true,
            config: {},
        }
    },
    computed: {
        ...mapState(['curComponent']),
        isDisabled() {
            return this.curComponent.animations.length > 1
        },
    },
    created() {
        const { label, animationTime, animationDelay, isLinear, isLoop = false, value } = this.curComponent.animations[this.curIndex] || {}
        this.config = {
            animationTime,
            animationDelay,
            label,
            isLoop,
            value,
            isLinear,
        }
    },
    methods: {
        handleCloseModal() {
            this.$emit('close')
        },
        handleSaveSetting() {
            const { isLoop } = this.config
            this.$store.commit('alterAnimation', {
                index: this.curIndex,
                data: {
                    animationTime: this.config.animationTime,
                    animationDelay: this.config.animationDelay,
                    isLinear: this.config.isLinear,
                    isLoop,
                },
            })
            eventBus.$emit('stopAnimation')
            this.handleCloseModal()
        },
    },
}
