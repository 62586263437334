//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStyle } from '@/utils/style'
import { mapState } from 'vuex'
import ComponentWrapper from './ComponentWrapper'
import { changeStyleWithScale } from '@/utils/translate'

export default {
    components: { ComponentWrapper },
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    computed: mapState([
        'componentData',
        'canvasStyleData',
    ]),
    methods: {
        changeStyleWithScale,
        
        getStyle,

        close() {
            this.$emit('change', false)
        },
    },
}
