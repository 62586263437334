//
//
//
//
//
//
//
//

export default {
    model: {
        prop: 'show',
        event: 'change',
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        hide() {
            this.$emit('change')
        },

        stopPropagation(e) {
            e.stopPropagation()
        },
    },
}
