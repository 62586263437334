//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SelectImage, UploadByFile } from '@/utils/api'
import { request } from '@/utils/request'
import toast from '@/utils/toast'
import { Loading } from 'element-ui'

export default {
    data() {
        return {
            tableData: [],
            pageNum: 1,
            pageSize: 5,
            total: 0,
            pictLoading: true,
        }
    },
    created() {
        this.getIndexList({
            pageNum: 1,
            pageSize: this.pageSize,
            fileType: 'pageImage',
        })
    },
    methods: {
        selected(row) {
            this.$emit('children', row)
        },
        handleCurrentChange(val) {
            this.getIndexList({
                pageNum: val,
                pageSize: this.pageSize,
                fileType: 'pageImage',
            })
        },
        getIndexList(params) {
            let loadingInstance = Loading.service({
                target: '.selected-image-div',
            })
            let vm = this
            request(SelectImage, 'get', params).then(res => {
                loadingInstance.close()
                if (res.data.code === 1) {
                    vm.tableData = res.data.data.list
                    vm.total = res.data.data.count
                } else {
                    vm.$message.error(res.data.message)
                }
            })
        },
        handleFileChange(e) {
            let vm = this
            const file = e.target.files[0]
            if (!file.type.includes('image')) {
                toast('只能插入图片')
                return
            }
            let loadingInstance = Loading.service({
                target: '.selected-image-div',
            })
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'pageImage')
            formData.append('data_name', 'AntBlessPage')
            formData.append('file_name', file.name)
            formData.append('file_size', file.size)
            formData.append('mime_type', file.type)
            const reader = new FileReader()
            reader.onload = (res) => {
                console.log(res)
                request(UploadByFile, 'post', formData).then(re => {
                    loadingInstance.close()
                    if (re.data.code === 1) {
                        vm.$message.success('上传成功')
                        vm.getIndexList({
                            pageNum: 1,
                            pageSize: vm.pageSize,
                            fileType: 'pageImage',
                        })
                    } else {
                        vm.$message.error(re.data.message)
                    }
                })
            }
            reader.readAsDataURL(file)
        },
        handleFileChange1(e) {
            let vm = this
            const file = e.target.files[0]
            if (!file.type.includes('image')) {
                toast('只能插入图片')
                return
            }
            let loadingInstance = Loading.service({
                target: '.selected-image-div',
            })
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'pageImage')
            formData.append('data_name', 'AntBlessPage')
            formData.append('file_name', file.name)
            formData.append('file_size', file.size)
            formData.append('mime_type', file.type)
            formData.append('is_cat', 1)
            const reader = new FileReader()
            reader.onload = (res) => {
                console.log(res)
                request(UploadByFile, 'post', formData).then(re => {
                    loadingInstance.close()
                    if (re.data.code === 1) {
                        vm.$message.success('上传成功')
                        vm.getIndexList({
                            pageNum: 1,
                            pageSize: vm.pageSize,
                            fileType: 'pageImage',
                        })
                    } else {
                        vm.$message.error(re.data.message)
                    }
                    document.querySelector('#input1').setAttribute('type', 'text')
                    document.querySelector('#input1').setAttribute('type', 'file')
                    document.querySelector('#input2').setAttribute('type', 'text')
                    document.querySelector('#input2').setAttribute('type', 'file')
                })
            }
            reader.readAsDataURL(file)
        },
    },
}
